import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  ActivatedRoute,
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { WorkspaceGuardService } from './workspace.guard.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(
    public auth: AuthService,
    public router: Router,
    private guard: NgxPermissionsGuard,
    private wkService: WorkspaceGuardService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const permissions = !!route && route.data ? route.data['permissions'] : {};
    if (permissions) {
      const permission = this.transformAppPermission(permissions);
      if (permission) {
        route.data.permissions.only = permission.only;
        route.data.permissions.except = permission.except;
        route.data.permissions.redirectTo = route.data.permissions.redirectTo
          ? route.data.permissions.redirectTo
          : permission.redirectTo;
      }
    }

    if (!this.auth.isAuthenticated()) {
      return this.autoLogin(route, state).then(u => {
        if (u) {
          return this.checkAllPermission(route, state);
        }
        const wkcode = this.getwkcode(route);
        const wkcodeurl = wkcode ? '/' + wkcode : '';
        if (state.url.indexOf('parent') > -1) {
          this.router.navigate([wkcodeurl + '/parent/login'], {
            queryParams: { returnUrl: state.url },
          });
        } else if (
          state.url.toLowerCase().indexOf('family') > -1 &&
          state.url.toLowerCase().indexOf('wlo') == -1
        ) {
          this.router.navigate([wkcodeurl + '/family/login'], {
            queryParams: { returnUrl: state.url },
          });
        } else {
          this.router.navigate([wkcodeurl + '/home/login'], {
            queryParams: { returnUrl: state.url },
          });
        }
        return false;
      });
    } else {
      return this.checkAllPermission(route, state);
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const permissions = !!route && route.data ? route.data['permissions'] : {};
    if (permissions) {
      const permission = this.transformAppPermission(permissions);
      if (permission) {
        route.data.permissions.only = permission.only;
        route.data.permissions.except = permission.except;
        route.data.permissions.redirectTo = route.data.permissions.redirectTo
          ? route.data.permissions.redirectTo
          : permission.redirectTo;
      }
    }
    return this.checkAllPermission(route, state, true);
  }

  private async autoLogin(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const user = await this.auth.loginWithToken();
    // if (user) {
    //     return true;
    //   } else {
    //     this.router.navigate(['/home/login'], { queryParams: { returnUrl: state.url}});
    //     return false;
    //   }
    const wkcode = this.getwkcode(route);
    const wkcodeurl = wkcode ? '/' + wkcode : '';
    try {
      const accode = route.queryParams.AccessCode || '';
      //sso 或 carecloud  单点登录，移除cc_token_t.避免登录两次
      if(accode){
        localStorage.removeItem('cc_token_t');
      }
      const user = await this.auth.loginWithToken(accode, false, wkcode);
      if (user) {
        return true;
      } else {
        this.router.navigate([wkcodeurl + '/home/login'], {
          queryParams: { returnUrl: state.url, autoLogin: true },
        });
        return false;
      }
    } catch (error) {
      console.log('guard error');
      console.log(error);
      this.router.navigate([wkcodeurl + '/home/login'], {
        queryParams: { returnUrl: state.url, autoLogin: true },
      });
      return false;
    }
  }

  private async checkAllPermission(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    activate = true,
  ) {
    const permissions = !!route && route.data ? route.data['permissions'] : {};
    if (!permissions) return true;

    const base = activate
      ? await this.guard.canActivate(route, state)
      : await this.guard.canActivateChild(route, state);
    const wk = await this.checkWorkspacePermission(route, state);
    if (base && wk) {
      return true;
    } else {
      const wkcode = this.getwkcode(route);
      const wkcodeurl = wkcode ? '/' + wkcode : '';
      this.router.navigate([wkcodeurl + '/pages/401']);
      return false;
    }
  }

  private async checkWorkspacePermission(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    const wkCode = this.getwkcode(route);
    if (wkCode) {
      const w = await this.wkService.setCurrentWorkspaceCode(wkCode);
      if (w) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private transformAppPermission(permissions): any {
    const defaultPermission = {
      only: [],
      except: [],
      redirectTo: '/pages/401',
    };
    const purePermissions = !!permissions && permissions.only ? permissions.only : null;
    if (purePermissions) {
      let exc: any = [];
      if (purePermissions instanceof Array) {
        purePermissions.forEach(item => {
          exc.push('!' + item);
        });
      } else if (purePermissions) {
        exc = '!' + purePermissions;
      }
      defaultPermission.only = purePermissions;
      defaultPermission.except = exc;
      return defaultPermission;
    } else {
      return null;
    }
  }

  private getwkcode(route: ActivatedRouteSnapshot) {
    let returnurl = '';
    if (route.params && route.params.wkcode) {
      returnurl = route.params.wkcode;
    } else if (route.children && route.children.length > 0) {
      returnurl =
        route.firstChild && route.firstChild.params && route.firstChild.params.wkcode
          ? route.firstChild.params.wkcode
          : '';
    } else {
      returnurl =
        route.parent && route.parent.params && route.parent.params.wkcode
          ? route.parent.params.wkcode
          : '';
    }
    return returnurl;
  }
}
